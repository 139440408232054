module.exports = {
  en: {
    path: 'en',
    locale: 'EN',
    default: true,
  },
  tr: {
    path: 'tr',
    locale: 'TR',
  },
}
