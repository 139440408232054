import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData } from 'react-intl'

// Locale data
import enData from 'react-intl/locale-data/en'
import trData from 'react-intl/locale-data/tr'

// Messages
import en from '../../i18n/en.json'
import tr from '../../i18n/tr.json'

// Style

// Components
//import Header from '../Header'

const messages = { en, tr }

addLocaleData([...enData, ...trData])

const Layout = ({ locale, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      <div className="children">{children}</div>
    </Fragment>
  </IntlProvider>
)

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Layout
